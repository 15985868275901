<template>
  <div class="zu11Div">
    <div class="mnq-box">
      <div class="mnq-scroll">
        <div v-for="(item, index) in htmlArr" :key="index">
          <div class="mnq-item mnq-title">
            <el-input :type="input" @blur="inputShiJiao" v-model="item.value" :class="item.classArr" placeholder="请输入"></el-input>
            <div class="cssBtnDiv">
              <i class="el-icon-bottom" @click="lowerClick(index)"></i>
              <i class="el-icon-top" @click="upClick(index)"></i>
              <i class="jiaCuDiv" @click="jiaCu(index)">B</i>
              <i class="el-icon-s-operation" @click="center(index)"></i>
              <i class="el-icon-delete-solid delBtn" @click="simDelete(index)"></i>
            </div>
          </div>
          <!-- <div class="mnq-item mnq-title" v-if="item.type == 'viceTitle'">

            <el-input @blur="inputShiJiao" v-model="item.value" :class="item.classArr" placeholder="请输入副标题"></el-input>
            <div class="cssBtnDiv">
              <i class="el-icon-bottom" @click="lowerClick(index)"></i>
              <i class="el-icon-top" @click="upClick(index)"></i>
              <i class="jiaCuDiv" @click="jiaCu(index)">B</i>
              <i class="el-icon-s-operation" @click="center(index)"></i>
              <i class="el-icon-delete-solid delBtn" @click="simDelete(index)"></i>
            </div>
          </div>
          <div class="mnq-item mnq-title" v-if="item.type == 'text'">
            <el-input type="textarea" @blur="inputShiJiao" v-model="item.value" :class="item.classArr" placeholder="请输入文案"></el-input>
            <div class="cssBtnDiv">
              <i class="el-icon-bottom" @click="lowerClick(index)"></i>
              <i class="el-icon-top" @click="upClick(index)"></i>
              <i class="jiaCuDiv" @click="jiaCu(index)">B</i>
              <i class="el-icon-s-operation" @click="center(index)"></i>
              <i class="el-icon-delete-solid delBtn" @click="simDelete(index)"></i>
            </div>
          </div> -->
        </div>
      </div>

      <div class="mnq-choice">
        <div class="mnq-choice-itme" v-for="(item, index) in choices" :key="index" @click="choiceClick(item)">
          <span>{{ item.label }}</span>
          <i class="el-icon-circle-plus"></i>
        </div>
      </div>

      <!-- <div class="mnq-choice">
        <div class="mnq-choice-itme" @click="copyClick('mnq')">
          <span>复制</span>
          <i class="el-icon-document-copy"></i>
        </div>
        <div class="mnq-choice-itme" @click="pasteClick('mnq')">
          <span>粘贴</span>
          <i class="el-icon-folder-add"></i>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  props: ['list'],
  components: {},
  data() {
    return {
      htmlArr: [
        // { type: 'title', value: '', classArr: [] },
        // { type: 'title', value: '234', classArr: [] },
      ],
      choices: [
        { type: 'title', label: '标题模块' },
        { type: 'viceTitle', label: '副标题模块' },
        // { type: 'img', label: '图片模块' },
        { type: 'text', label: '文案模块' },
        // { type: 'video', label: '视频模块' },
        // { type: 'textLink', label: '链接模块' },
        // { type: 'vote', label: '投票模块' },
        // { type: 'topic', label: '话题模块' },
      ],
    }
  },
  watch: {
    list() {
      console.log('list1', this.list)
      this.htmlArr = this.list || []
    },
  },
  created() {},
  mounted() {
    // console.log('list', this.list)
  },
  methods: {
    inputShiJiao() {
      this.$emit('htmlArrChange', this.htmlArr)
    },
    // 标题click
    choiceClick(item) {
      switch (item.type) {
        case 'title':
          this.htmlArr.push({ type: 'title', value: '', classArr: [] })
          break
        case 'viceTitle':
          this.htmlArr.push({ type: 'viceTitle', value: '', classArr: [] })
          break
        case 'text':
          this.htmlArr.push({ type: 'text', value: '', classArr: [] })
          break
      }
    },
    // 向下
    lowerClick(index) {
      console.log('indexindex', index)
      if (index < this.htmlArr.length - 1) {
        let item = this.htmlArr[index]
        let xiaItem = this.htmlArr[index + 1]
        let temp = item
        this.htmlArr[index + 1] = temp
        this.htmlArr[index] = xiaItem
        this.$forceUpdate()
        console.log('-----', this.htmlArr)
        this.$emit('htmlArrChange', this.htmlArr)
      }
    },
    // 向上
    upClick(index) {
      if (index > 0) {
        let item = this.htmlArr[index]
        let shangItem = this.htmlArr[index - 1]
        let temp = item
        this.htmlArr[index - 1] = temp
        this.htmlArr[index] = shangItem
        this.$forceUpdate()
        console.log('-----', this.htmlArr)
        this.$emit('htmlArrChange', this.htmlArr)
      }
    },
    // 居中
    center(index) {
      let classArr = this.htmlArr[index].classArr
      let num = classArr.findIndex((el) => el == 'juZhong')
      if (num > -1) {
        classArr.splice(num, 1)
      } else {
        classArr.push('juZhong')
      }
      this.$forceUpdate()
      console.log('-----', this.htmlArr)
      this.$emit('htmlArrChange', this.htmlArr)
    },
    // 加粗
    jiaCu(index) {
      let classArr = this.htmlArr[index].classArr
      let num = classArr.findIndex((el) => el == 'jiaCuDiv')
      if (num > -1) {
        classArr.splice(num, 1)
      } else {
        classArr.push('jiaCuDiv')
      }
      this.$forceUpdate()
      console.log('-----', this.htmlArr)
      this.$emit('htmlArrChange', this.htmlArr)
    },
    simDelete(index) {
      this.htmlArr.splice(index, 1)
      this.$forceUpdate()
      this.$emit('htmlArrChange', this.htmlArr)
    },
  },
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.mnq-box {
  display: flex;
  align-items: center;
  .mnq-scroll {
    width: 375px;
    min-height: 300px;
    padding: 10px;
    border: 1px solid #dcdfe6;
    // box-shadow: 0px 0px 8px #636363;
    overflow: hidden;
    position: relative;
    .mnq-item {
      position: relative;
      margin-bottom: 8px;
      &:hover .cssBtnDiv {
        display: flex;
      }
      .el-input__inner {
        width: 100%;
        border: 0;
        height: auto;
        padding: 0 18px;
      }

      .el-upload {
        width: 100%;
        display: block;
      }
      .el-upload-dragger {
        width: 100%;
        border-left: 0;
        border-right: 0;
      }
      .el-textarea__inner {
        width: 100%;
        border: 0;
        padding: 0 18px;
        height: auto;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        // border:1px dashed #d9d9d9;
        // border-left:0;
        // border-right: 0;
      }
    }
    .mnq-img {
      img {
        width: 100%;
        display: block;
      }
    }
    .cssBtnDiv {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 16px;
      color: #409eff;
      display: none;
      align-items: center;
      i {
        cursor: pointer;
        // background-color: pink;
        margin-right: 3px;
      }
      .delBtn {
        color: #f56c6c;
      }
    }

    .mnq-title {
      .el-input__inner {
        font-size: 18px;
        font-weight: bold;
        color: #000;
        line-height: 22px;
        word-break: break-all;
      }
    }
  }

  .mnq-choice {
    border: 1px solid #dcdfe6;
    margin-left: 60px;
    width: 160px;
    .mnq-choice-itme {
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      text-align: center;
      border-bottom: 1px solid #dcdfe6;
      font-size: 12px;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
.juZhong {
  ::v-deep .el-input__inner {
    text-align: center;
  }
}
.jiaCuDiv {
  ::v-deep .el-input__inner {
    font-weight: 700;
  }
}
</style>
