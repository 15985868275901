<template>
  <div>
    <el-card>
      <div>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addClick()">添加</el-button>
      </div>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="mtName" label="子活动名称" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="tenantName" label="公司名称" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="modelTypeName" label="模式" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="状态" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{ scope.row.status | ziHuoDongStatus }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="endTime" label="结束时间" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="activityName" label="关联规则" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="warning" v-if="scope.row.status == 2" @click="updateStatus(scope.row)">下架</el-button>
            <el-button size="mini" type="primary" v-else @click="updateStatus(scope.row)">上架</el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateClick(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteClick(scope.row)">删除</el-button>
            <!-- <el-button type="primary" icon="el-icon-edit" size="mini" @click="modelHiqClick(scope.row)">选题</el-button> -->
            <!-- <el-button type="primary" icon="el-icon-edit" size="mini" @click="modelHiqListClick(scope.row)">查看选题</el-button> -->
            <!-- <el-button type="primary" icon="el-icon-edit" size="mini" @click="inspireAwardClick(scope.row)">奖励配置</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>

    <!-- 添加子活动 -->
    <el-dialog title="添加子活动" :visible.sync="ziHuoDongDia" v-if="ziHuoDongDia" width="800px" @close="ziHuoDongDia = false">
      <zu1 @close="ziHuoDongDia = false" :rowData="rowData" :projectId="projectId" @addZiHuoDongEnd="addZiHuoDongEnd"></zu1>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import { commonMixin } from '@/utils/mixin/index.js'
import uploadImg from '@/components/common/uploadImg/Index.vue'
import zu1 from './zu1.vue'
import { getDataFromLocalStorage } from '@/utils/tools.js'
import Cookies from 'js-cookie'
export default {
  components: {
    pagination,
    uploadImg,
    zu1,
  },
  mixins: [commonMixin],
  data() {
    return {
      params: {
        tenantName: '',
        pageNum: 1,
        pageSize: 10,
        projectId: '',
      },
      records: [],
      total: 0,
      rowData: '',
      ziHuoDongDia: false, // 子活动dia
      projectId: '',
    }
  },

  mounted() {
    const huoDongRow = getDataFromLocalStorage('huoDongRow', true)
    if (!huoDongRow.id) {
      return this.$router.replace('/')
    }
    this.projectId = huoDongRow.id
    this.params.projectId = this.projectId
    this.params.tenantName = huoDongRow.tenantName
    this.getData()
  },
  methods: {
    async getData() {
      const res = await this.$API.ziHuoDong.getZiHuoDongList(this.params)
      if (res.status != 200) return this.$message.error(res.msg)
      const { records, total } = res.data
      this.records = records
      this.total = parseInt(total)
    },

    // 添加 子活动
    addClick() {
      this.rowData = ''
      this.ziHuoDongDia = true
    },
    // 编辑
    updateClick(row) {
      this.rowData = row
      this.ziHuoDongDia = true
    },
    // 删除
    deleteClick(row) {
      const params = {
        id: row.id,
        tenantName: row.tenantName,
      }
      this.$confirm('此操作将永久删除该子活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async (res) => {
          if (res == 'confirm') {
            const res = await this.$API.ziHuoDong.delete(params)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$message.success(res.data)
            this.params.pageNum = 1
            this.getData()
          } else {
            // resolve(true)
          }
        })
        .catch((err) => err)
    },
    modelHiqClick(row) {
      window.localStorage.setItem('Model', JSON.stringify(row))
      this.$router.push(`/xuanTiManage/xuanTi?id=${row.id}&tenantName=${row.tenantName}&hiqNum=${row.hiqNum}`)
    },
    modelHiqListClick(row) {
      window.localStorage.setItem('Model', JSON.stringify(row))
      this.$router.push('/xuanTiManage/seeXuanTi')
    },
    inspireAwardClick(row) {
      window.localStorage.setItem('Model', JSON.stringify(row))
      this.$router.push({
        name: 'inspireAward',
      })
    },
    updateStatus(row) {
      let status = ''
      let txt = ''
      if (row.status == 2) {
        status = 3
        txt = '下架'
      } else {
        status = 2
        txt = '上架'
      }
      this.$confirm('确定[' + txt + ']活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async (res) => {
        if (res == 'confirm') {
          await this.$API.ziHuoDong.updateStatus({ id: row.id, status, tenantName: row.tenantName })
          this.getData()
        }
      })
    },
    addZiHuoDongEnd() {
      this.ziHuoDongDia = false
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}
.el-cascader {
  width: 100%;
}
.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
