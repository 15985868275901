<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="子活动名称" prop="mtName">
        <el-input v-model="ruleForm.mtName" clearable></el-input>
      </el-form-item>
      <el-form-item label="模式" prop="modelTypeId">
        <el-select v-model="ruleForm.modelTypeId" clearable placeholder="请选择活动模式">
          <el-option v-for="item in moShiArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="子活动时间" prop="value1">
        <el-date-picker
          v-model="ruleForm.value1"
          clearable
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="changeDate"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="子活动缩略图" prop="imgIcon">
        <uploadImg @imgChage="imgChage" name="imgIcon" :imgUrl="ruleForm.imgIcon"></uploadImg>
      </el-form-item>
      <el-form-item label="子活动海报" prop="imgBg">
        <uploadImg @imgChage="imgChage" name="imgBg" :imgUrl="ruleForm.imgBg"></uploadImg>
      </el-form-item>
      <!-- serList -->
      <el-form-item label="隐私条款" required="">
        <zu1_1 @htmlArrChange="tiaoKuanChange" :list="serList"></zu1_1>
      </el-form-item>
      <el-form-item label="主题图" prop="imgBanner">
        <uploadImg @imgChage="imgChage" name="imgBanner" :imgUrl="ruleForm.imgBanner"></uploadImg>
      </el-form-item>
      <el-form-item label="活动规则说明" required="">
        <zu1_1 @htmlArrChange="huodongChange" :list="lotList"></zu1_1>
      </el-form-item>
      <el-form-item label="关联规则" prop="activityId">
        <el-select v-model="ruleForm.activityId" collapse-tags clearable placeholder="请选择关联规则">
          <el-option v-for="item in guiZeArr" :key="item.id" :label="item.name" :value="String(item.id)"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="btnDiv">
          <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
          <el-button @click="close">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
/* eslint-disable */
import zu1_1 from './zu1_1.vue'
import uploadImg from '@/components/common/uploadImg/Index.vue'
import { getDataFromLocalStorage, makeForm } from '@/utils/tools.js'
var moment = require('moment')
export default {
  props: ['rowData', 'projectId'],
  components: { zu1_1, uploadImg },
  data() {
    return {
      ruleForm: {
        mtName: '', // 子活动名称
        modelTypeId: '', // 模式
        value1: '', // 子活动时间
        startTime: '',
        endTime: '',
        imgIcon: '', // 子活动缩略图
        imgBg: '', // 子活动海报
        imgBanner: '', // 主题图
        lotname: [], // 关联规则
        serList: [], // 条款
        id: '',
        activityId: '',
      },
      rules: {
        mtName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        modelTypeId: [{ required: true, message: '请选择模式', trigger: 'blur' }],
        type: [{ required: true, message: '请选择模式', trigger: 'change' }],
        value1: [{ required: true, message: '请选择时间', trigger: ['blur', 'change'] }],
        imgIcon: [{ required: true, message: '请选择时间', trigger: ['blur', 'change'] }],
        imgBg: [{ required: true, message: '请选择时间', trigger: ['blur', 'change'] }],
      },
      huoDongRow: '',
      moShiArr: [],
      guiZeArr: [],
      serList: [],
      lotList: [],
      fmt: 'YYYY-MM-DD HH:mm:ss',
    }
  },
  created() {},
  async mounted() {
    this.getmoShiArr()
    if (this.rowData) {
      makeForm(this.ruleForm, this.rowData)

      this.ruleForm.value1 = [moment(this.rowData.startTime).format(this.fmt), moment(this.rowData.endTime).format(this.fmt)]

      if (this.rowData.serValue) {
        this.serList = JSON.parse(this.rowData.serValue)
      }
      console.log('this.serList', this.serList)

      // [
      //   { type: 'title', value: 'a', classArr: ['jiaCuDiv', 'juZhong'] },
      //   { type: 'viceTitle', value: 'b', classArr: [] },
      //   { type: 'text', value: 'adfasdf', classArr: [] },
      // ]
      if (this.rowData.lotValue) {
        this.lotList = JSON.parse(this.rowData.lotValue)
      }
    }
    this.huoDongRow = getDataFromLocalStorage('huoDongRow', true)
    // 根据公司 获取规则数据源
    this.getGuiZeDaraSources()
    // 获取模式数据源
  },
  methods: {
    // 获取规则数据源
    async getGuiZeDaraSources() {
      let canshu = {
        tenantName: this.huoDongRow.tenantName,
      }
      const res = await this.$API.guiZe.getGuiZeList({ ...canshu, isUse: 1 })
      const res1 = await this.$API.guiZe.getGuiZeList(canshu)
      if (res.status != 200) return this.$message.error(res.msg)
      const { records } = res.data
      // res.data.forEach((t) => (t.id = Number(t.id)))
      // console.log('res.data', res1.data, this.rowData)
      if (res.data.length) {
        this.guiZeArr = res.data || []
      }

      if (res1.data.length) {
        let tmp = res1.data.find((t) => t.id == this.rowData.activityId)
        if (tmp) {
          this.guiZeArr.push(tmp)
        }
      }
    },
    // 获取模式数据源
    async getmoShiArr() {
      let canshu = { pageNum: 1, pageSize: 100 }
      const res = await this.$API.moShi.getMoShiList(canshu)
      if (res.status != 200) return this.$message.error(res.msg)
      const { records } = res.data
      // records.forEach((t) => (t.id = Number(t.id)))
      this.moShiArr = records
    },

    //
    tiaoKuanChange(val) {
      // console.log('val', val)

      this.ruleForm.serValue = JSON.stringify(val)
    },
    huodongChange(val) {
      this.ruleForm.lotValue = JSON.stringify(val)
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.ruleForm.type = this.ruleForm.modelTypeId
          this.ruleForm.startTime = moment(this.ruleForm.value1[0]).format(this.fmt)
          this.ruleForm.endTime = moment(this.ruleForm.value1[1]).format(this.fmt)
          this.ruleForm.tenantName = this.huoDongRow.tenantName
          console.log('this.ruleForm.value1', this.ruleForm)
          this.ruleForm.projectId = this.projectId
          if (this.rowData) {
            const res = await this.$API.ziHuoDong.update(this.ruleForm)
            if (res.status != 200) return this.$message.error(res.msg)
          } else {
            const res = await this.$API.ziHuoDong.addZiHuoDong(this.ruleForm)
            if (res.status != 200) return this.$message.error(res.msg)
          }
          this.$emit('addZiHuoDongEnd')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    imgChage(item) {
      console.log('itemitem', item)
      this.ruleForm[item.name] = item.url
    },
    close() {
      this.$emit('close')
    },
    changeDate(a, b, c) {
      console.log('xxxx', a, b, c)
    },
  },
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.btnDiv {
  display: flex;
  justify-content: flex-end;
}
</style>
